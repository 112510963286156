import DOMPurify from 'dompurify';
import getFeatureToggle from './hn-feature-toggle';
export const TRUSTED_TYPES_FEATURE_NAME = 'TrustedTypes';
const hasDefaultTrustedTypesPolicy = () => !!window.trustedTypes?.defaultPolicy;
/**
 * Sjekk om det finnes en default policy for trusted types og om Trusted Types er togglet på.
 *
 * @returns true dersom Trusted Types skal enables
 */
export const isTrustedTypesEnabled = () => hasDefaultTrustedTypesPolicy() && getFeatureToggle(TRUSTED_TYPES_FEATURE_NAME);
const DefaultSanitizeConfig = {
    ADD_ATTR: ['target'],
};
/**
 * Sanitize html før den vises til bruker. Returnerer Trusted Types dersom dette er i bruk.
 *
 * @param html string med HTML som skal sanitizes
 * @returns sanitized HTML
 */
export const sanitize = (html) => {
    if (DOMPurify && typeof DOMPurify.sanitize === 'function') {
        return DOMPurify.sanitize(html ?? '', { ...DefaultSanitizeConfig, RETURN_TRUSTED_TYPE: isTrustedTypesEnabled() });
    }
    return '';
};
