import LanguageLocales from '@helsenorge/core-utils/constants/languages';
/**
 * Konverterer språkkode fra CMS til LanguageLocale som primært brukes på tjenester
 * @param cultureName Språkkode som brukes i CMS
 * @returns LanguageLocale som brukes på tvers av tjenester og CMS
 */
export const mapCultureNameToLanguageLocale = (cultureName) => {
    switch (cultureName) {
        case 'no': {
            return LanguageLocales.NORWEGIAN;
        }
        case 'nn': {
            return LanguageLocales.NORWEGIAN_NYNORSK;
        }
        case 'en': {
            return LanguageLocales.ENGLISH;
        }
        case 'lt': {
            return LanguageLocales.LITHUANIAN;
        }
        case 'pl': {
            return LanguageLocales.POLISH;
        }
        case 'ru': {
            return LanguageLocales.RUSSIAN;
        }
        case 'ro': {
            return LanguageLocales.ROMANIAN;
        }
        case 'se': {
            return LanguageLocales.SAMI_NORTHERN;
        }
    }
};
