import React from 'react';

import AnchorLink from '@helsenorge/designsystem-react/components/AnchorLink';
import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';
import { NotificationPanelVariants } from '@helsenorge/designsystem-react/components/NotificationPanel/NotificationPanel';

import { useAdditionalPageContent } from '../driftspanel/use-additionalPageContent';

import styles from './styles.module.scss';

const Infopanel: React.FC = () => {
  const [meldinger] = useAdditionalPageContent();

  const infomeldinger = meldinger?.filter(m => m.messageType === 'infomelding' && m.showAfterTitle);

  if (!infomeldinger || infomeldinger.length === 0) {
    return null;
  }

  return (
    <div className={styles.infopanel}>
      {infomeldinger.map(melding => (
        <NotificationPanel
          key={melding.id}
          label={melding.title}
          dismissable={false}
          variant={melding.type.toLocaleLowerCase() as NotificationPanelVariants}
          expanderButtonText={melding.moreDetailsButtonText}
          expanderButtonClosedText={melding.moreDetailsButtonText}
          expanderChildren={
            melding.moreDetails && (
              <>
                <p>{melding.moreDetails}</p>
                {melding.url && melding.urlTitle && (
                  <AnchorLink href={melding.url} target={melding.target}>
                    {melding.urlTitle}
                  </AnchorLink>
                )}
              </>
            )
          }
        >
          {melding.introduction}
        </NotificationPanel>
      ))}
    </div>
  );
};

export default Infopanel;
