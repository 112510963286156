import { ActionTypes } from '../types/entities';

import { DispatchAction } from './actions';
import { GlobalState, BrukerState, ErrorState, LoadingState } from './initialState';

export const reducer = (state: GlobalState, action: DispatchAction): GlobalState => {
  switch (action.type) {
    case ActionTypes.error: {
      const errorState: ErrorState = { ...state.error, ...action.payload.error };
      const loadingState: LoadingState = { ...state.loading, ...action.payload.loading };
      const updatedPayloadWithNestedStates = { ...action.payload, error: errorState, loading: loadingState };
      const newState: GlobalState = { ...state, ...updatedPayloadWithNestedStates };
      return newState;
    }
    case ActionTypes.SetLoadingAction: {
      const loadingState: LoadingState = { ...state.loading, ...action.payload.loading };
      const updatedPayloadWithNestedStates = { ...action.payload, loading: loadingState };
      const newState: GlobalState = { ...state, ...updatedPayloadWithNestedStates };
      return newState;
    }
    case ActionTypes.GetUserAction: {
      const errorState: ErrorState = { ...state.error, ...action.payload.error };
      const loadingState: LoadingState = { ...state.loading, ...action.payload.loading };
      const brukerState: BrukerState = { ...state.bruker, ...action.payload?.bruker };
      const updatedPayloadWithNestedStates = {
        ...action.payload,
        error: errorState,
        loading: loadingState,
        bruker: brukerState,
      };
      const updatedState: GlobalState = { ...state, ...updatedPayloadWithNestedStates };
      return updatedState;
    }
    default: {
      const errorState: ErrorState = { ...state.error, ...action.payload.error };
      const loadingState: LoadingState = { ...state.loading, ...action.payload.loading };
      const updatedPayloadWithNestedStates = {
        ...action.payload,
        error: errorState,
        loading: loadingState,
      };
      const updatedState: GlobalState = { ...state, ...updatedPayloadWithNestedStates };

      return updatedState;
    }
  }
};
