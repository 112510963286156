import { AntallUlesteRepresentertGuid } from '../types/entities/hendelsesmeny.v2entities';

import { fallbackHeaderFooter } from '@helsenorge/core-cms/header-skeleton/data';
import { HeaderFooterDTO } from '@helsenorge/core-cms/types/entities/cms.v1entities';
import LanguageLocales from '@helsenorge/core-utils/constants/languages';
import { Personverninnstilling } from '@helsenorge/framework-utils/types/entities';

export interface GlobalState {
  loading?: LoadingState;
  error?: ErrorState;
  isAuthorized?: boolean;
  isMinHelseTjenester?: boolean;
  bruker?: BrukerState;
  language?: LanguageLocales;
  headerFooter: HeaderFooterDTO;
  islistenerReady?: boolean;
  /** Antall uleste for den personen som er valgt */
  antallUlesteForValgtRepresentasjon?: number;
  /** Liste med antall uleste per person bruker kan representere */
  antallUlesteForAlleRepresentasjoner?: AntallUlesteRepresentertGuid[];
}

export interface ErrorState {
  global?: string;
  representasjonsforholdError?: string;
  profileError?: string;
  messageError?: string;
  hendelseError?: string;
  kunAntallUlesteError?: string;
  headerFooterError?: string;
  antallUlesteError?: string;
}

export interface LoadingState {
  global?: boolean;
  isRepresentasjonsforholdLoading?: boolean;
  isMessageLoading?: boolean;
  isHendelseLoading?: boolean;
  isKunAntallUlesteLoading?: boolean;
  isHeaderFooterLoading?: boolean;
  isAntallUlesteLoading?: boolean;
}

export interface BrukerState {
  visPersonvelger?: boolean;
  navn?: string;
  sistInnlogget?: string;
  samtykkeKonverteringInformert?: boolean;
  representertBruker?: string;
  erRepresentasjon?: boolean;
  erMellom12Og16?: boolean;
  harRepresentasjon?: boolean;
  harBarnMedAnnenAdresse?: boolean;
  harBarnUnder16UtenForeldreansvar?: boolean;
  harBarnOver11?: boolean;
  harBarnOver12?: boolean;
  harBarnOver16?: boolean;
  scopes?: string[];
  personverninnstillinger?: Personverninnstilling[];
  harInnsynssperre?: boolean;
}

// This is the default state when initialized
export const initialState: GlobalState = {
  loading: {
    global: false,
    isMessageLoading: false,
    isHendelseLoading: false,
    isKunAntallUlesteLoading: false,
    isHeaderFooterLoading: false,
  },
  error: {
    global: undefined,
    profileError: undefined,
    messageError: undefined,
    hendelseError: undefined,
    kunAntallUlesteError: undefined,
  },
  isAuthorized: false,
  isMinHelseTjenester: false,
  bruker: undefined,
  antallUlesteForValgtRepresentasjon: 0,
  islistenerReady: false,
  headerFooter: fallbackHeaderFooter,
};
