import { isCustomElementRegistered } from './register';
import { getAssetsUrl } from '../hn-page';
import { error } from '../logger';
const isTemplateRenderedServerSide = (templateElement) => {
    if (templateElement && templateElement.content.children.length > 0) {
        return true;
    }
    return false;
};
const createMicrowebTemplate = (templateName) => {
    const templateElement = document.createElement('template');
    templateElement.setAttribute('id', templateName);
    document.head.appendChild(templateElement);
    return templateElement;
};
const isWebpackAssetsJson = (json) => json.entrypoints !== undefined;
/**
 * Finn det første link-elementet i document.head som inneholder helsenorge.css
 * @returns URL til helsenorge.css i document.head, med hash i URLen
 */
const getHelsenorgeCssUrl = () => {
    const elementList = document.head.querySelectorAll(`link[href^="${getAssetsUrl()}/core/static/css/helsenorge."]`);
    const linkElement = Array.from(elementList)[0];
    return linkElement?.href;
};
const blockUntilEvent = (target, event) => {
    return new Promise(resolve => target.addEventListener(event, resolve, { passive: true, once: true }));
};
export const fetchWebComp = async (props) => {
    // Venter med å sjekke om template er allerede lagt til server-side inntil HTML-en er innlastet.
    // Dette for å unngå undøvendig fetch av assets.json pga. race condition.
    if (document.readyState === 'loading') {
        await blockUntilEvent(document, 'DOMContentLoaded');
    }
    const { domain, entryName, componentName, includeHelsenorgeCss, templateName } = props;
    const microwebTemplate = document.getElementById(templateName || `${componentName}-template`);
    if (!isCustomElementRegistered(componentName) && !isTemplateRenderedServerSide(microwebTemplate)) {
        const assetsUrl = `${domain}/assets.json`;
        // Sørg for å alltid hente nyeste assets.json
        // https://developer.mozilla.org/en-US/docs/Web/API/Request/cache
        return fetch(assetsUrl, { cache: 'no-cache' })
            .then(response => {
            if (response.status === 200) {
                const resp = response.json();
                return resp;
            }
            return Promise.reject(Error(`Error while fetching asset-manifest.json from ${domain}/assets.json`));
        })
            .then((assets) => {
            const templateElement = microwebTemplate ?? createMicrowebTemplate(templateName || `${componentName}-template`);
            const appendStylesheet = (href) => {
                const cssElement = document.createElement('link');
                cssElement.setAttribute('href', href);
                cssElement.rel = 'stylesheet';
                if (templateElement)
                    templateElement.content.appendChild(cssElement);
            };
            const appendScript = (src, type) => {
                const scriptElement = document.createElement('script');
                scriptElement.setAttribute('src', src);
                type && scriptElement.setAttribute('type', type);
                document.head.appendChild(scriptElement);
            };
            if (includeHelsenorgeCss) {
                const helsenorgeCssUrl = getHelsenorgeCssUrl();
                appendStylesheet(helsenorgeCssUrl || `${getAssetsUrl()}/core/static/css/helsenorge/helsenorge.css`);
            }
            if (isWebpackAssetsJson(assets)) {
                assets.entrypoints[entryName]
                    ?.filter(x => !x.endsWith('.map'))
                    .map((asset) => {
                    if (asset.endsWith('.css')) {
                        appendStylesheet(`${domain}/${asset}`);
                    }
                    else {
                        appendScript(`${domain}/${asset}`);
                    }
                });
            }
            else {
                // assets.json is in Vite format
                assets[entryName]?.css?.map(asset => {
                    appendStylesheet(`${domain}/${asset}`);
                });
                assets[entryName] && appendScript(`${domain}/${assets[entryName]?.file}`, 'module');
            }
        })
            .catch(e => {
            error(e, assetsUrl);
        });
    }
    return Promise.resolve();
};
