import { useEffect } from 'react';
export const useKeyDown = (ref, key, callback) => {
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === key || (Array.isArray(key) && key.includes(event.key))) {
                callback(event);
            }
        };
        ref?.current?.addEventListener('keydown', handleKeyDown);
        return () => {
            ref?.current?.removeEventListener('keydown', handleKeyDown);
        };
    }, [ref, callback]);
};
