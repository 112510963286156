/// <reference types="@helsenorge/framework-utils/types/hn"/>
import { createRef } from 'react';
import { WindowActionEnum } from '../types/entities';
import { getHelsenorgeUrl } from '@helsenorge/framework-utils/hn-proxy-service';
// Remove social security number from string
export const removeSsn = (string) => {
    return string.replace(/[0-9]{2}[0,1][0-9][0-9]{2}[ ]?[0-9]{5}/, '*fødselsnummer*');
};
// Removes specific characters from string
export const sanitizeSearchValue = (string) => {
    const newString = string
        .replace(/</g, '') // <
        .replace(/script>/g, '') // script>
        .replace(/(\\|\/)/, ''); // / or \
    return removeSsn(newString);
};
// Returns the max number of suggestions defined
export const getMaxSuggestions = () => {
    return 6;
};
// Returns the search API URL
export const getSearchApiUrl = () => {
    return window.HN?.Rest?.HelseNorgeUrl ?? '';
};
// Gets a number of refs and returns an array of newly created Refs
export const updateSuggestionListRef = (refCount, maxCount) => {
    const refArray = [];
    if (!refCount)
        return refArray;
    const totalCount = maxCount && refCount > maxCount ? maxCount : refCount;
    for (let i = 0; i < totalCount; i++)
        refArray.push(createRef());
    return refArray;
};
// Send the user to the serach results and updates the url
export const goToSearchResults = (url, action, searchArguments) => {
    const query = searchArguments.query !== undefined ? searchArguments.query : '';
    const crQueryParam = searchArguments.contentReference != null ? `&cr=${searchArguments.contentReference}` : '';
    const referrerQueryParam = searchArguments.referrer != null ? `&referrer=${searchArguments.referrer}` : '';
    const updatedUrl = `${url}?q=${query}${crQueryParam}${referrerQueryParam}`;
    if (action === WindowActionEnum.None) {
        window.history.replaceState({ path: updatedUrl }, '', updatedUrl);
    }
    else {
        const helsenorgeUrl = getHelsenorgeUrl();
        window.location.replace(`${helsenorgeUrl}${updatedUrl}`);
    }
};
// Fetching suggestions from given url
export const fetchSuggestions = (url, errMsg) => {
    return fetch(url).then((response) => {
        if (!response || !response.ok) {
            return Promise.reject(Error(errMsg));
        }
        return response.json();
    });
};
// Method which is called when a new search is triggered
export const triggerSearch = (url, searchArguments, actionOnSearch) => {
    if (actionOnSearch) {
        actionOnSearch({
            contentReference: searchArguments.contentReference,
            query: searchArguments.query,
            skip: searchArguments.skip,
        });
        goToSearchResults(url, WindowActionEnum.None, searchArguments);
    }
    else {
        goToSearchResults(url, WindowActionEnum.RefreshPage, searchArguments);
    }
};
// Updates the array of queries and suggestions
export const handleSuggestions = (query, suggestions, previousQueriesWithAutosuggestions, updateQueriesWithAutosuggestions) => {
    // removes the previous QueryWithAutosuggestions at that key if it exists on the current state
    const updatedQueriesWithAutosuggestions = [...previousQueriesWithAutosuggestions];
    updatedQueriesWithAutosuggestions.filter(function (autosuggestion) {
        return autosuggestion.query === query;
    });
    // adds the new QueryWithAutosuggestions
    const newQueryWithAutosuggestions = {
        query,
        suggestions,
    };
    updatedQueriesWithAutosuggestions.push(newQueryWithAutosuggestions);
    // updates the QueriesWithAutosuggestions in the state
    updateQueriesWithAutosuggestions(updatedQueriesWithAutosuggestions);
};
// Method to be called by keyPress to handle actions according to keys
export const handleKeySwitch = (event, prevQuery, highlightedSuggestionIndex, listSuggestionsRef, searchasyoutypeTimeoutId, updateSearchasyoutypeTimeoutId, handleArrowNavigation, shouldRenderSuggestions, onSearchSubmit, updateHighlightedSuggestionIndex) => {
    const suggestionListRef = [...listSuggestionsRef.current] || [];
    switch (event.key) {
        case 'Tab': // Tab
        case 'Escape': // Escape
            shouldRenderSuggestions(false);
            break;
        case 'Enter': // Enter
            shouldRenderSuggestions(false);
            if (searchasyoutypeTimeoutId !== null) {
                clearTimeout(searchasyoutypeTimeoutId);
                updateSearchasyoutypeTimeoutId(null);
            }
            onSearchSubmit(event.target.value);
            break;
        case 'Up': // Up
        case 'ArrowUp': // Up
            {
                const prevIndex = highlightedSuggestionIndex >= 0 ? highlightedSuggestionIndex - 1 : suggestionListRef.length - 1;
                handleArrowNavigation(event, prevIndex, prevQuery);
            }
            break;
        case 'Down': // Down
        case 'ArrowDown': // Down
            {
                const nextIndex = highlightedSuggestionIndex < suggestionListRef.length - 1 ? highlightedSuggestionIndex + 1 : -1;
                handleArrowNavigation(event, nextIndex, prevQuery);
            }
            break;
        default:
            shouldRenderSuggestions(true);
            updateHighlightedSuggestionIndex(-1);
            break;
    }
};
