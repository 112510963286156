import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import classnames from 'classnames';
import Button from '@helsenorge/designsystem-react/components/Button';
import Icon, { IconSize } from '@helsenorge/designsystem-react/components/Icon';
import X from '@helsenorge/designsystem-react/components/Icons/X';
import Spacer from '@helsenorge/designsystem-react/components/Spacer';
import Title from '@helsenorge/designsystem-react/components/Title';
import { useIsVisible } from '@helsenorge/designsystem-react/hooks/useIsVisible';
import { useKeyDown } from '../useKeyDown';
import { SURVEY_Z_INDEX } from '../utils';
import styles from './styles.module.scss';
export var KeyboardEventKey;
(function (KeyboardEventKey) {
    KeyboardEventKey["Escape"] = "Escape";
})(KeyboardEventKey || (KeyboardEventKey = {}));
const InvitationStep = ({ title, children, primaryButtonText, onPrimary, secondaryButtonText, onSecondary, closeButtonText, onClose, }) => {
    const dialogRef = useRef(null);
    const handleKeyboardNavigation = (event) => {
        event.preventDefault();
        if (event.key === KeyboardEventKey.Escape) {
            onClose();
        }
    };
    useKeyDown(dialogRef, KeyboardEventKey.Escape, handleKeyboardNavigation);
    const isFullyVisible = useIsVisible(dialogRef);
    const TITLE_ID = 'survey-invitation-title';
    const TEXT_ID = 'survey-invitation-text';
    return (_jsxs("div", { role: "dialog", "aria-labelledby": TITLE_ID, "aria-describedby": TEXT_ID, className: classnames(styles['survey-invitation-step'], isFullyVisible && styles['survey-invitation-step--fully-visible']), style: { zIndex: SURVEY_Z_INDEX }, ref: dialogRef, children: [_jsx(Button, { onClick: onClose, wrapperClassName: styles['survey-invitation-step__close'], ariaLabel: closeButtonText, variant: "borderless", children: _jsx(Icon, { svgIcon: X, size: IconSize.XSmall }) }), _jsx(Title, { id: TITLE_ID, htmlMarkup: "span", appearance: "title3", className: styles['survey-invitation-step__title'], children: title }), _jsx(Spacer, { size: "s" }), _jsx("div", { id: TEXT_ID, className: styles['survey-invitation-step__content'], children: children }), _jsxs("div", { className: styles['survey-invitation-step__buttons'], children: [_jsx(Button, { onClick: onPrimary, children: primaryButtonText }), secondaryButtonText && (_jsx(Button, { onClick: onSecondary, variant: "outline", children: secondaryButtonText }))] })] }));
};
export default InvitationStep;
