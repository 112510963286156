import React from 'react';

import classNames from 'classnames';

import Icon, { IconSize, SvgIcon } from '@helsenorge/designsystem-react/components/Icon';
import X from '@helsenorge/designsystem-react/components/Icons/X';

import { useHover } from '@helsenorge/designsystem-react';
import { theme } from '@helsenorge/designsystem-react';

import styles from './styles.module.scss';

interface Props extends Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, 'aria-label'> {
  icon: SvgIcon;
  isActive?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  afterIconChildren?: React.ReactNode;
  htmlMarkup?: 'button' | 'a';
  href?: string;
  testId?: string;
}

export const HeaderButton = React.forwardRef((props: Props, ref: React.ForwardedRef<HTMLButtonElement | HTMLAnchorElement>) => {
  const { icon, isActive, onClick, children, testId, htmlMarkup = 'button' } = props;
  const { hoverRef, isHovered } =
    htmlMarkup === 'button'
      ? useHover<HTMLButtonElement>(ref as React.RefObject<HTMLButtonElement>)
      : useHover<HTMLAnchorElement>(ref as React.RefObject<HTMLAnchorElement>);

  const headerButtonClasses = classNames(styles['header-button'], {
    [styles['header-button--active']]: isActive,
  });
  const headerButtonTextClasses = classNames(styles['header-button__text'], {
    [styles['header-button__text--active']]: isActive,
  });

  const contents = (
    <>
      <span className={styles['header-button__icon']}>
        <Icon size={IconSize.XSmall} svgIcon={isActive ? X : icon} color={theme.palette.black} isHovered={isHovered} />
        {props.afterIconChildren}
      </span>
      <span className={headerButtonTextClasses}>{children}</span>
    </>
  );

  return (
    <>
      {htmlMarkup === 'button' && (
        <button
          ref={hoverRef as React.RefObject<HTMLButtonElement>}
          className={headerButtonClasses}
          onClick={(e): void => onClick && onClick(e)}
          aria-expanded={isActive}
          data-testid={`header-button-${testId}`}
          aria-label={props['aria-label']}
        >
          {contents}
        </button>
      )}
      {htmlMarkup === 'a' && (
        <a
          ref={hoverRef as React.RefObject<HTMLAnchorElement>}
          className={headerButtonClasses}
          href={props.href}
          data-testid={`header-button-${testId}`}
          aria-label={props['aria-label']}
        >
          {contents}
        </a>
      )}
    </>
  );
});

HeaderButton.displayName = 'HeaderButton';

export default HeaderButton;
