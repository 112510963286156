import { NotificationMessage } from '../../types/entities';

import { Survey } from '@helsenorge/core-cms/survey-invitation';
import LanguageLocales from '@helsenorge/core-utils/constants/languages';
import { get } from '@helsenorge/framework-utils/cms-content-api-service';

export declare type NotificationPanelVariants = 'info' | 'warn' | 'error' | 'crisis';
export declare type MeldingstypeVariants = 'infomelding' | 'driftsmelding';

export interface IAdditionalPageContent {
  messages: NotificationMessage[];
  surveys: Survey[];
}

/**
 * Hent driftsmeldinger, infomeldinger og spørreundersøkelser for side på tjenester.helsenorge.no
 * @param path Path til siden, f.eks. provesvar
 * @param languageCode Språkkode
 * @returns Driftsmeldinger, infomeldinger og spørreundersøkelser
 */
export const getContentForPath = async (path: string, languageCode?: LanguageLocales): Promise<IAdditionalPageContent | null> =>
  get('additionalpagecontent/search', { path, languageCode });

/**
 * Hent driftsmeldinger, infomeldinger og spørreundersøkelser for side på www.helsenorge.no
 * @param contentReference Side-ID i CMS
 * @param languageCode Språkkode
 * @returns Driftsmeldinger, infomeldinger og spørreundersøkelser
 */
export const getContentForContentReference = async (
  contentReference: string,
  languageCode?: LanguageLocales
): Promise<IAdditionalPageContent | null> =>
  get(`additionalpagecontent/contentreference/${contentReference}`, {
    languageCode,
  });
