import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ExpanderList from '@helsenorge/designsystem-react/components/ExpanderList';
import LazyIcon from '@helsenorge/designsystem-react/components/LazyIcon';
import Title from '@helsenorge/designsystem-react/components/Title';
import { trackNavigation } from '@helsenorge/framework-utils/adobe-analytics';
import { getAbsoluteUrl } from '../shared/links';
import './style.scss';
const TemaMenu = (props) => {
    if (!props.menuItems || props.menuItems.length < 1) {
        return null;
    }
    else {
        return (_jsx("div", { className: `temamenu temamenu--${props.type}`, children: _jsx(ExpanderList, { large: true, color: "cherry", children: props.menuItems.map((menuItem, i) => {
                    return (_jsx(ExpanderList.Expander, { large: true, onExpand: (isExpanded) => {
                            isExpanded && trackNavigation('toppmeny', menuItem.menuTitle, 'sidetopp');
                        }, className: "temamenu__item", title: _jsx(Title, { htmlMarkup: 'span', appearance: 'title2', margin: 0, children: menuItem.menuTitle }), children: menuItem.linkGroupsList.length > 0 && (_jsx("ul", { className: "temamenu__expanderlist", children: menuItem.linkGroupsList.map((menuExpanderlist, j) => {
                                return (_jsxs("li", { className: "temamenu__expanderlist__item", children: [menuExpanderlist.groupHeading && (_jsx("div", { className: 'temamenu__expanderlist__item__title', children: _jsx(Title, { htmlMarkup: 'h3', appearance: 'title5', margin: 0, children: menuExpanderlist.groupHeading }) })), menuExpanderlist.linksList.length > 0 && (_jsx("ul", { className: "temamenu__expanderlistexpander", children: menuExpanderlist.linksList.map((link, k) => {
                                                return (_jsx("li", { className: `temamenu__expanderlistexpander__item ${k < menuExpanderlist.linksList.length / 2
                                                        ? 'temamenu__expanderlistexpander__item--firsthalf'
                                                        : 'temamenu__expanderlistexpander__item--secondhalf'}`, children: _jsxs("a", { href: getAbsoluteUrl(link), title: link.title ?? undefined, onClick: () => trackNavigation('toppmeny', `${menuItem.menuTitle}: ${link.text}`, 'sidetopp'), className: `temamenu__expanderlistexpander__anchor ${!menuExpanderlist.showIcons || !link.icon ? 'temamenu__expanderlistexpander__anchor--noicon' : ''}`, children: [menuExpanderlist.showIcons && link.icon && (_jsx("span", { className: "temamenu__expanderlistexpander__anchor__icon", children: _jsx(LazyIcon, { iconName: link.icon }) })), _jsx("span", { className: "temamenu__expanderlistexpander__anchor__text", children: _jsx("span", { className: "temamenu__expanderlistexpander__anchor__text__underline", children: link.text }) })] }) }, k));
                                            }) }))] }, j));
                            }) })) }, i));
                }) }) }));
    }
};
export default TemaMenu;
