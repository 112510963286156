import React, { useContext, useEffect, useState } from 'react';

import { HeaderFooterEvents } from '@helsenorge/framework-utils/web-component/constants';
import { SubscribeContext } from '@helsenorge/framework-utils/web-component/context';

import AppInvitation from './app-invitation';
import { globalStateContext } from '../../store';

const AppInvitationWrapper: React.FC = () => {
  const subscribe = useContext(SubscribeContext);
  const globalState = useContext(globalStateContext);
  const [showDialog, setShowDialog] = useState(false);
  const [onAppOpen, setOnAppOpen] = useState<(() => void) | null>(null);
  const [onWebLogin, setOnWebLogin] = useState<(() => void) | null>(null);
  const [onClose, setOnClose] = useState<(() => void) | null>(null);

  const appInvitationText = globalState.headerFooter?.header.appInvitation;
  const hasAppInvitationText =
    appInvitationText &&
    appInvitationText.title &&
    appInvitationText.description &&
    appInvitationText.primaryButtonText &&
    appInvitationText.secondaryButtonText &&
    appInvitationText.closeButtonLabel;

  useEffect(() => {
    subscribe(HeaderFooterEvents.showappinvitation, (event: CustomEvent) => {
      setOnAppOpen(() => event.detail.onAppOpen);
      setOnWebLogin(() => event.detail.onWebLogin);
      setOnClose(() => event.detail.onClose);
      setShowDialog(true);
    });
  }, []);

  useEffect(() => {
    if (onWebLogin && !hasAppInvitationText) {
      onWebLogin();
    }
  }, [onWebLogin]);

  return appInvitationText &&
    appInvitationText.title &&
    appInvitationText.description &&
    appInvitationText.primaryButtonText &&
    appInvitationText.secondaryButtonText &&
    appInvitationText.closeButtonLabel &&
    showDialog &&
    onAppOpen &&
    onWebLogin ? (
    <AppInvitation
      title={appInvitationText.title}
      description={appInvitationText.description}
      primaryButtonText={appInvitationText.primaryButtonText}
      secondaryButtonText={appInvitationText.secondaryButtonText}
      closeButtonLabel={appInvitationText.closeButtonLabel}
      onClose={() => {
        setShowDialog(false);
        onClose && onClose();
      }}
      onAppOpen={onAppOpen}
      onWebLogin={onWebLogin}
    />
  ) : null;
};

export default AppInvitationWrapper;
