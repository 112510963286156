import React, { useContext, useRef, useState } from 'react';

import classNames from 'classnames';
import { UnmountClosed } from 'react-collapse';

import { MenuType } from '../../types/entities';

import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';
import { useOutsideEvent } from '@helsenorge/designsystem-react/hooks/useOutsideEvent';

import { useLayoutEvent } from '@helsenorge/designsystem-react';

import { globalStateContext } from '../../store';
import MenuBasic from '../menu-basic';
import MenuProfile from '../menu-profile';
import MenuSearch from '../menu-search';

import styles from './styles.module.scss';

export interface Props {
  headerRef: React.RefObject<HTMLDivElement>;
  menuType?: MenuType;
  isActive?: boolean;
  backgroundColor?: string;
  onClose?: () => void;
  testId?: string;
}

const renderMenuAccordingToType = (menyType?: MenuType): React.JSX.Element | null => {
  switch (menyType) {
    case MenuType.search:
      return <MenuSearch padding />;
    case MenuType.profile:
      return <MenuProfile />;
    case MenuType.basic:
      return <MenuBasic />;
    default: {
      return null;
    }
  }
};

const Menu = (props: Props): React.JSX.Element => {
  const globalState = useContext(globalStateContext);
  const isMenuVisible = !!props.isActive;
  const isProfile = props.menuType === MenuType.profile;
  const scrollingTimeOutRef = useRef<number | undefined>();
  const [isScrolling, setIsScrolling] = useState(false);

  useOutsideEvent(props.headerRef, (event: MouseEvent) => {
    const clickedScrollbar = event.clientX ? event.clientX >= document.documentElement.offsetWidth : false;
    const clickedMacOSScrollbar = isScrolling && (event.target as HTMLElement).nodeName === 'HTML';

    if (!clickedScrollbar && !clickedMacOSScrollbar && isMenuVisible && props.onClose) {
      props.onClose();
    }
  });

  const handleScroll = (): void => {
    setIsScrolling(true);
    window.clearTimeout(scrollingTimeOutRef.current);

    scrollingTimeOutRef.current = window.setTimeout(function () {
      setIsScrolling(false);
    }, 3000);
  };

  useLayoutEvent(handleScroll, ['scroll']);

  const renderSection = (): React.ReactNode => {
    return (
      <section
        className={classNames(styles.menu, {
          [styles['menu--profile']]: isProfile,
          [styles['menu--active']]: isMenuVisible,
          [styles['menu--blueberry']]: props?.backgroundColor === 'blueberry',
          [styles['menu--cherry']]: props?.backgroundColor === 'cherry',
          [styles['menu--unsetcolor']]: !props?.backgroundColor,
        })}
        data-testid={props.testId}
      >
        <UnmountClosed
          theme={{
            collapse: classNames(styles['menu__collapse'], {
              [styles['menu__collapse--search']]: isMenuVisible && props.menuType === MenuType.search,
              [styles['menu__collapse--basic']]: isMenuVisible && props.menuType === MenuType.basic,
              [styles['menu__collapse--profile']]: isMenuVisible && props.menuType === MenuType.profile,
            }),
          }}
          isOpened={isMenuVisible}
        >
          {globalState?.error?.global && (
            <div className="container">
              <NotificationPanel fluid={false} variant={'error'}>
                {globalState?.error?.global}
              </NotificationPanel>
            </div>
          )}
          <div className={styles['menu__innerwrapper']} data-testid="innerwrap">
            {renderMenuAccordingToType(props?.menuType)}
          </div>
        </UnmountClosed>
      </section>
    );
  };

  return isProfile ? <div>{renderSection()}</div> : <>{renderSection()}</>;
};

export default Menu;
