import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@helsenorge/designsystem-react/components/Button';
import Icon, { IconSize } from '@helsenorge/designsystem-react/components/Icon';
import ArrowUpRight from '@helsenorge/designsystem-react/components/Icons/ArrowUpRight';
import { SURVEY_Z_INDEX } from '../utils';
import styles from './styles.module.scss';
const StartSurvey = ({ children, onClick }) => {
    return (_jsx("div", { style: { zIndex: SURVEY_Z_INDEX }, children: _jsxs(Button, { onClick: onClick, wrapperClassName: styles['start-survey'], className: styles['start-survey__button'], children: [children, _jsx(Icon, { svgIcon: ArrowUpRight, size: IconSize.XSmall })] }) }));
};
export default StartSurvey;
