import { hasLoggedInCookie } from '@helsenorge/framework-utils/cookie';
import { isAuthorized } from '@helsenorge/framework-utils/hn-authorize';
import { getTjenesterUrl } from '@helsenorge/framework-utils/hn-proxy-service';
/**
 * Returnerer url prefixet med tjenestedomenet dersom linken er til en tjenesteside og bruker er pålogget
 * Hvis bruker ikke er pålogget, prefixes url med helsenorge-domenet. Dersom url ikke er til en tjeneste, gjøres ingen endringer.
 * @param link Link fra content-api-respons
 * @returns absolutt url med riktig domene
 */
export const getAbsoluteUrl = (link) => {
    const userIsLoggedIn = isAuthorized() || hasLoggedInCookie();
    if (link.tjenesteRelativeUrl && (userIsLoggedIn || link.redirectToTjenester)) {
        return getTjenesterUrl() + link.tjenesteRelativeUrl;
    }
    return link.href;
};
