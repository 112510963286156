import { getHelsenorgeUrl, getTjenesterUrl } from '../../hn-proxy-service';
/**
 * Replaces "{tjenesterUrl}" and "{helsenorgeUrl}" with url according to environment.
 * @param url text that may include {tjenesterUrl} or {helsenorgeUrl}.
 */
export const envSafeUrl = (url) => {
    return url
        .replace(/{tjenesterUrl}/g, getTjenesterUrl())
        .replace(/{helsenorgeUrl}/g, getHelsenorgeUrl())
        .replace(/tjenesterUrl/g, getTjenesterUrl())
        .replace(/helsenorgeUrl/g, getHelsenorgeUrl());
};
