import React from 'react';

import classNames from 'classnames';

import { NotificationPanelVariants } from '@helsenorge/designsystem-react/components/NotificationPanel/NotificationPanel';
import ServiceMessage from '@helsenorge/designsystem-react/components/ServiceMessage';
import Spacer from '@helsenorge/designsystem-react/components/Spacer';
import { SpacerSizes } from '@helsenorge/designsystem-react/theme/spacers';

import LanguageLocales from '@helsenorge/core-utils/constants/languages';
import { WebcompProps } from '@helsenorge/framework-utils/web-component/register';

import { useAdditionalPageContent } from './use-additionalPageContent';

import styles from './styles.module.scss';
interface DriftspanelProps {
  contentreference?: string;
  language?: LanguageLocales;
  emptyspacersize?: SpacerSizes;
}

export const Driftspanel: React.FC<DriftspanelProps & WebcompProps> = ({ contentreference, language, emptyspacersize }) => {
  const [meldinger, handleClosed] = useAdditionalPageContent(contentreference, language);

  const driftsmeldinger = meldinger?.filter(m => m.messageType === 'driftsmelding' || !m.showAfterTitle);

  if (!driftsmeldinger || driftsmeldinger.length === 0) {
    return emptyspacersize ? <Spacer size={emptyspacersize} testId={`spacer${emptyspacersize}`} /> : null;
  }

  //TODO: Unngår sot på denne. Legges inn i cms-et når nynorsk er klart.
  const dismissButtonText = language === LanguageLocales.ENGLISH ? 'Remove message' : 'Fjern melding';

  const driftspanelClassNames = classNames(styles.driftspanel, 'd-print-none');

  return (
    <div className={driftspanelClassNames} data-testid={'driftspanel'}>
      {driftsmeldinger.map(driftsmelding => (
        <ServiceMessage
          key={driftsmelding.id}
          label={driftsmelding.title}
          info={driftsmelding.introduction}
          extraInfo={driftsmelding.moreDetails}
          variant={driftsmelding.type.toLocaleLowerCase() as NotificationPanelVariants}
          dismissable={driftsmelding.messageType === 'driftsmelding'}
          onDismiss={(): void => handleClosed(driftsmelding)}
          closeBtnText={dismissButtonText}
          testId="melding"
          urlTitle={driftsmelding.urlTitle}
          url={driftsmelding.url}
          target={driftsmelding.target}
        />
      ))}
    </div>
  );
};

export default Driftspanel;
