import registerWebComp from '@helsenorge/framework-utils/web-component/register';

import Driftspanel from './components/driftspanel';
import Footer from './components/footer';
import HeaderWrapper from './components/header-wrapper';
import Infopanel from './components/infopanel';

async function enableMocking(): Promise<ServiceWorkerRegistration | void> {
  if (import.meta.env.MODE !== 'mocks') {
    return;
  }

  const { worker } = await import('./mocks/browser');

  return worker.start({
    onUnhandledRequest: 'bypass',
  });
}

enableMocking().then(() => {
  registerWebComp(HeaderWrapper, 'hn-webcomp-header', { events: true }, 'hn-webcomp-header-footer-template');
  registerWebComp(Footer, 'hn-webcomp-footer', { events: true }, 'hn-webcomp-header-footer-template');
  registerWebComp(Driftspanel, 'hn-webcomp-driftspanel', { events: true }, 'hn-webcomp-header-footer-template');
  registerWebComp(Infopanel, 'hn-webcomp-infopanel', { events: true }, 'hn-webcomp-header-footer-template');
});
