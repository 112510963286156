import { Dispatch } from 'react';

import { ActionTypes } from '../../types/entities';

import LanguageLocales from '@helsenorge/core-utils/constants/languages';

import { DispatchAction } from '../actions';

export const setLanguage = (dispatch: Dispatch<DispatchAction>, language: LanguageLocales): void => {
  dispatch({ type: ActionTypes.SetLanguage, payload: { language } }); // @todo: Denne er ikke i reduceren
};
