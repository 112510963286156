import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { fetchWebComp } from './fetcher';
const WebCompConsumer = props => {
    useEffect(() => {
        fetchWebComp(props);
    }, []);
    const webCompFromMicroFrontend = React.createElement(props.componentName, props.componentProps, props.children);
    return _jsx(React.Fragment, { children: webCompFromMicroFrontend });
};
export default WebCompConsumer;
