import React, { useContext } from 'react';

import classNames from 'classnames';

import { MenuType } from '../../types/entities';

import Icon, { IconSize } from '@helsenorge/designsystem-react/components/Icon';
import AvatarIcon from '@helsenorge/designsystem-react/components/Icons/Avatar';
import ChevronDown from '@helsenorge/designsystem-react/components/Icons/ChevronDown';
import ChevronUp from '@helsenorge/designsystem-react/components/Icons/ChevronUp';
import SupportingPerson from '@helsenorge/designsystem-react/components/Icons/SupportingPerson';

import { globalStateContext } from '../../store';
import Menu from '../menu';

import styles from './styles.module.scss';

interface ProfileButtonProps {
  onClose: () => void;
  headerRef: React.RefObject<HTMLDivElement>;
  isActive: boolean;
  menuColor?: string;
  menuType?: MenuType;
  onClick: (type: MenuType) => void;
  profileMenuElementHoverObject: {
    hoverRef: React.RefObject<HTMLDivElement>;
    isHovered: boolean;
  };
}

const ProfileButton: React.FC<ProfileButtonProps> = ({
  onClose,
  headerRef,
  isActive,
  menuColor,
  menuType,
  onClick,
  profileMenuElementHoverObject,
}: ProfileButtonProps) => {
  const globalState = useContext(globalStateContext);
  const profileMenuElementIsHovered = profileMenuElementHoverObject.isHovered;
  const closeMenuText = globalState.headerFooter?.header.fasteTekster.closeMenu ?? 'Lukk';
  const profileMenuAriaLabel = isActive && menuType === MenuType.profile ? closeMenuText : undefined;
  const personIcon = globalState?.bruker?.erRepresentasjon ? SupportingPerson : AvatarIcon;

  const profileButtonClasses = classNames(styles['profile-button'], { [styles['profile-button--active']]: isActive });
  const profileButtonTextClassNames = classNames(styles['profile-button__text'], {
    [styles['profile-button__text--active']]: isActive,
  });

  return (
    <>
      <button
        className={profileButtonClasses}
        onClick={(): void => (isActive && menuType === MenuType.profile ? onClose() : onClick(MenuType.profile))}
        aria-expanded={isActive && menuType === MenuType.profile}
        tabIndex={0}
        data-testid="profile-button"
      >
        <Icon size={IconSize.XSmall} svgIcon={personIcon} isHovered={profileMenuElementIsHovered} ariaLabel={profileMenuAriaLabel} />
        <span className={profileButtonTextClassNames}>
          {globalState.bruker?.representertBruker ? globalState.bruker?.representertBruker : ''}
        </span>
        <Icon
          className={styles['profile-button__icon']}
          size={IconSize.XSmall}
          svgIcon={isActive && menuType === MenuType.profile ? ChevronUp : ChevronDown}
          ariaLabel={
            isActive && menuType === MenuType.profile
              ? (globalState.headerFooter?.header.fasteTekster.closeMenu ?? 'Lukk')
              : (globalState.headerFooter?.header.fasteTekster.openMenu ?? 'Åpne')
          }
        />
      </button>
      {isActive && <div className={styles['dividing-line']} />}
      <Menu
        headerRef={headerRef}
        menuType={MenuType.profile}
        isActive={isActive && menuType == MenuType.profile}
        backgroundColor={menuColor}
        onClose={onClose}
      />
    </>
  );
};

export default ProfileButton;
