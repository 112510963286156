import { Personverninnstilling } from '@helsenorge/framework-utils/types/entities';

/**
 * Sjekk om bruker har samtykke
 * @param personverninnstillinger Brukerens aktive utvalgte personverninnstillinger
 * @returns true hvis bruker har et samtykke
 */
export const hasSamtykke = (personverninnstillinger: Personverninnstilling[] | undefined): boolean =>
  personverninnstillinger?.some(personverninnstilling =>
    [
      Personverninnstilling.SamtykkeDigitalHelsetjeneste,
      Personverninnstilling.SamtykkeJournalinnsyn,
      Personverninnstilling.SamtykkeRegisterinnsyn,
    ].includes(personverninnstilling)
  ) ?? false;

/**
 * Sjekk om bruker har et spesielt scope
 * @param scopes Brukerens scopes
 * @param scope Navn på scope som skal sjekkes
 * @returns true hvis bruker har scopet
 */
const hasScope = (scopes: string[] | undefined, scope: string): boolean => {
  return scopes?.includes(scope) ?? false;
};

/**
 * Sjekk om bruker kan hente uleste meldinger
 * @param scopes Brukerens scopes
 * @returns true hvis bruker har scopet "meldinger"
 */
export const hasMeldingerScope = (scopes: string[] | undefined): boolean => hasScope(scopes, 'meldinger');

/**
 * Sjekk om bruker kan hente nye hendelser
 * @param scopes Brukerens scopes
 * @returns true hvis bruker har scopet "hendelsesmeny"
 */
export const hasHendelsesmenyScope = (scopes: string[] | undefined): boolean => hasScope(scopes, 'hendelsesmeny');
