import React from 'react';

import classNames from 'classnames';

import { IconName } from '@helsenorge/designsystem-react/components/Icons/IconNames';
import LazyIcon from '@helsenorge/designsystem-react/components/LazyIcon';

import { mapCultureNameToLanguageLocale } from '@helsenorge/core-cms/shared/language';
import { getAbsoluteUrl } from '@helsenorge/core-cms/shared/links';
import { GlobalFooterSupportHotline, IconLinkDTO } from '@helsenorge/core-cms/types/entities/cms.v1entities';
import { isMobileUA } from '@helsenorge/core-utils/user-agents-utils';
import { trackNavigation } from '@helsenorge/framework-utils/adobe-analytics';
import SafeHTML from '@helsenorge/framework-utils/components/safe-html';
import { setUserLanguage } from '@helsenorge/framework-utils/hn-language';
import { error } from '@helsenorge/framework-utils/logger';

import styles from './styles.module.scss';

/* Function to fetch footer data from content API  */

/* Function to render the Hotline information as a first row is the first column */
export const renderSupportHotlineLink = (supportHotline: GlobalFooterSupportHotline): React.JSX.Element => {
  const { displayText, displayNumber, schemaNumber, phoneNumberPrefix, phoneNumberSuffix, icon } = supportHotline;

  const footerIconClassNames = classNames(styles['footer__element__icon'], styles['footer__element__icon--alignstart']);

  return (
    <>
      {icon && (
        <span className={footerIconClassNames}>
          <LazyIcon size={32} color="white" iconName={icon as IconName} />
        </span>
      )}
      <a
        href={`tel:${schemaNumber}`}
        className={styles['footer__element__link']}
        onClick={(): void => trackNavigation('sidebunn', displayText, 'sidebunn')}
      >
        <span className={styles['footer__element__text']}>
          {phoneNumberPrefix} {displayNumber} {phoneNumberSuffix}
          <br />
        </span>
        <span className={classNames(styles['footer__element__text'], styles['footer__element__text--small'])}>{displayText}</span>
      </a>
    </>
  );
};

/* Function to render the Hotline information as a first row is the first column */
export const renderSupportHotlineLinkSimplified = (supportHotline: GlobalFooterSupportHotline): React.JSX.Element => {
  const { displayText, displayNumber, schemaNumber } = supportHotline;

  const elementClassNames = classNames(styles['footer__element'], styles['footer__element--simplified']);
  const textElementClassNames = classNames(styles['footer__element__text'], styles['footer__element__text--simplified']);
  const linkElementClassNames = classNames(styles['footer__element__link'], styles['footer__element__link--simplified']);

  return (
    <div className={elementClassNames}>
      <span className={textElementClassNames}>{displayText}</span>
      <a
        href={`tel:${schemaNumber}`}
        className={linkElementClassNames}
        onClick={(): void => trackNavigation('sidebunn', displayText, 'sidebunn')}
      >
        {displayNumber}
      </a>
    </div>
  );
};

/* Function to render the custom text in footer simplified*/
export const renderCustomFooterTextSimplified = (text: string | React.JSX.Element): React.JSX.Element => {
  const textClassNames = classNames(styles['footer__element__text'], styles['footer__element__text--simplified']);
  const elementClassNames = classNames(styles['footer__element'], styles['footer__element--simplified']);

  return (
    <div className={elementClassNames}>
      {typeof text === 'string' ? <SafeHTML html={text} className={textClassNames} /> : <span className={textClassNames}>{text}</span>}
    </div>
  );
};

/* Function to render the simplified footer content */
export const renderSimplifiedFooterContent = (
  simplifiedFootertext?: string,
  supportHotline?: GlobalFooterSupportHotline
): React.JSX.Element | string => {
  if (simplifiedFootertext) {
    return renderCustomFooterTextSimplified(simplifiedFootertext);
  } else if (supportHotline) {
    return renderSupportHotlineLinkSimplified(supportHotline);
  }
  return '';
};

/* Function to render each column of the footer */
export const renderFooterColumn = (
  linkList: Array<IconLinkDTO>,
  colIndex: number,
  supportHotline?: GlobalFooterSupportHotline,
  languageLinkList?: Array<IconLinkDTO>
): React.JSX.Element => {
  const isFirstColumn = colIndex === 0;

  const handleLinkClick = (link: IconLinkDTO): void => {
    trackNavigation('sidebunn', link.text, 'sidebunn');
    const locale = mapCultureNameToLanguageLocale(link.cultureName);
    if (locale) {
      setUserLanguage(locale);
    }
  };

  const columnClassNames = classNames({
    [styles[`footer__column--1`]]: colIndex === 1,
    [styles[`footer__column--2`]]: colIndex === 2,
  });
  const elementClassNames = classNames(styles.footer__element);

  const renderFooterLinks = (footerLinkList: Array<IconLinkDTO>, seperator = false): React.ReactNode =>
    footerLinkList?.map((link: IconLinkDTO, index) => (
      <li
        className={classNames(
          styles.footer__element,
          seperator && index !== footerLinkList.length - 1 && styles['footer__element--separator']
        )}
        key={index}
      >
        {link.icon && (
          <span className={styles['footer__element__icon']}>
            <LazyIcon size={32} color="white" iconName={link.icon as IconName} />
          </span>
        )}
        <a
          className={styles['footer__element__link']}
          href={getAbsoluteUrl(link)}
          target={link.target ?? undefined}
          rel={link.target === '_blank' ? 'noopener noreferrer' : undefined}
          title={link.title ?? undefined}
          onClick={(): void => handleLinkClick(link)}
        >
          <span className={styles['footer__element__text']}>{link.text}</span>
        </a>
      </li>
    ));

  return (
    <div className={columnClassNames} key={colIndex}>
      <ul className={styles['footer__column__innerwrapper']}>
        {!!supportHotline?.displayNumber && isFirstColumn && (
          <li className={elementClassNames} key="guide-number">
            {renderSupportHotlineLink(supportHotline)}
          </li>
        )}
        {renderFooterLinks(linkList)}
        {languageLinkList && isFirstColumn && (
          <li className={styles.footer__element}>
            <ul className={styles['footer__language-list']}>{renderFooterLinks(languageLinkList, true)}</ul>
          </li>
        )}
      </ul>
    </div>
  );
};

/* Function to set min-height on main-content-wrapper
Calculates based on (100vh - {footerHeight + extraFooterHeight} - {headerHeight} ) */
export const setStickyFooter = (): void => {
  if (!isMobileUA()) {
    try {
      const mainContentWrapper = document.getElementById('main-content-wrapper');
      const footerElementFromDOM = document.querySelector('hn-webcomp-footer');

      if (mainContentWrapper && footerElementFromDOM) {
        // Calculates based on top point og the mainContentWrapper in order to have the height from header included (with margin)
        const headerHeight = mainContentWrapper.getBoundingClientRect().top + window.scrollY;
        const footerHeight = footerElementFromDOM.getBoundingClientRect().bottom - mainContentWrapper.getBoundingClientRect().bottom;
        const wrapperHeight = `calc(100vh - ${footerHeight}px - ${headerHeight}px)`;
        mainContentWrapper.style.minHeight = wrapperHeight;
      }
    } catch (e) {
      error('Feil under forsøk på å gjøre footer sticky', e);
    }
  }
};
