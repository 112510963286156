import React, { useContext } from 'react';

import cn from 'classnames';

import Title from '@helsenorge/designsystem-react/components/Title';

import Search from '@helsenorge/core-cms/search';

import { globalStateContext } from '../../store';

import styles from './styles.module.scss';

interface MenuSearchProps {
  /** @deprecated Remove when new Varsler menu item is permanently enabled */
  padding?: boolean;
}

const MenuSearch: React.FC<MenuSearchProps> = props => {
  const globalState = useContext(globalStateContext);

  return (
    <div className={cn(styles['menu-search'], props.padding && styles['menu-search--padding'])}>
      <Title htmlMarkup={'h2'} appearance={'title2'} margin={0}>
        {globalState.headerFooter?.header.searchMenu.headerMenuSearchTitle}
      </Title>
      <Search
        searchUrl={globalState.headerFooter?.header.searchMenu.searchPageUrl ?? '/sok/'}
        label={globalState.headerFooter?.header.searchMenu.headerMenuSearchLabel ?? undefined}
        submitLabel={globalState.headerFooter?.header.searchMenu.headerMenuSearchSubmit ?? undefined}
        searchInputId={'globalSearch'}
        autoFocusSearchInput
        isAutoSuggestDisabled
        large={false}
      />
    </div>
  );
};

export default MenuSearch;
