import React, { useContext } from 'react';

import Badge from '@helsenorge/designsystem-react/components/Badge';
import Bell from '@helsenorge/designsystem-react/components/Icons/Bell';

import { getTjenesterUrl } from '@helsenorge/framework-utils/hn-proxy-service';

import { globalStateContext } from '../../../store';
import HeaderButton from '../header-button';

import styles from './styles.module.scss';

const VarslerButton: React.FC = () => {
  const globalState = useContext(globalStateContext);

  const antallUleste: number = globalState.antallUlesteForValgtRepresentasjon ?? 0;

  const getAriaLabel = (): string => {
    switch (antallUleste) {
      case 0:
        return globalState.headerFooter.header.fasteTekster.varslerAriaLabelNone;
      case 1:
        return globalState.headerFooter.header.fasteTekster.varslerAriaLabelOne;
      default:
        return globalState.headerFooter.header.fasteTekster.varslerAriaLabelMultiple.replace('{count}', antallUleste.toString(10) ?? '');
    }
  };

  const antallUlesteForAlle: number =
    globalState.antallUlesteForAlleRepresentasjoner?.reduce((total, representasjon) => total + representasjon.antall, 0) ?? 0;

  const getAriaLabelForAlle = (): string => {
    switch (antallUlesteForAlle) {
      case 0:
        return globalState.headerFooter.header.fasteTekster.varslerAriaLabelNone;
      default:
        return globalState.headerFooter.header.fasteTekster.varslerAriaLabelSome;
    }
  };

  if (globalState.bruker?.visPersonvelger) {
    return (
      <HeaderButton
        aria-label={getAriaLabelForAlle()}
        icon={Bell}
        htmlMarkup={'a'}
        href={`${getTjenesterUrl()}/varsler`}
        afterIconChildren={antallUlesteForAlle > 0 && <Badge color="cherry" className={styles.varsler} />}
        testId={'varsler'}
      >
        {globalState.headerFooter.header.fasteTekster.headerButtonVarsler}
      </HeaderButton>
    );
  }

  return (
    <HeaderButton
      aria-label={getAriaLabel()}
      icon={Bell}
      htmlMarkup={'a'}
      href={`${getTjenesterUrl()}/varsler`}
      afterIconChildren={
        antallUleste > 0 && (
          <Badge color="cherry" className={styles.varsler}>
            {antallUleste}
          </Badge>
        )
      }
      testId={'varsler'}
    >
      {globalState.headerFooter.header.fasteTekster.headerButtonVarsler}
    </HeaderButton>
  );
};

export default VarslerButton;
