import React, { useContext } from 'react';

import Button from '@helsenorge/designsystem-react/components/Button';
import Icon from '@helsenorge/designsystem-react/components/Icon';
import Logout from '@helsenorge/designsystem-react/components/Icons/Logout';
import Modal from '@helsenorge/designsystem-react/components/Modal';
import { Breakpoint, useBreakpoint } from '@helsenorge/designsystem-react/hooks/useBreakpoint';

import { trackNavigation } from '@helsenorge/framework-utils/adobe-analytics';

import { globalStateContext } from '../../store';
import { loggUt } from '../../store/actions';
import HeaderButton from '../header/header-button';

export declare enum ModalVariants {
  normal = 'normal',
  warning = 'warning',
  error = 'error',
}

interface LogoutButtonProps {
  headerButton?: boolean;
}

const LogoutButton: React.FC<LogoutButtonProps> = ({ headerButton }) => {
  const globalState = useContext(globalStateContext);
  const [showModal, setModal] = React.useState(false);
  const buttonText = globalState.headerFooter.header.fasteTekster.headerButtonLogout;
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint < Breakpoint.sm;

  if (!globalState.isAuthorized) {
    return null;
  }
  const logOut = (): void => {
    trackNavigation('logg ut', '', 'sidetopp');
    const params = new URLSearchParams(window.location.search);

    if (params.get('action') === 'noredirect') setModal(true);

    if (params.get('action') !== 'noredirect') loggUt(globalState.headerFooter.header.fasteTekster.headerButtonLogoutUrl);
  };
  return (
    <>
      {headerButton ? (
        <HeaderButton icon={Logout} onClick={logOut} testId={'logout'}>
          {buttonText}
        </HeaderButton>
      ) : (
        <Button fluid={!isMobile ? undefined : true} onClick={logOut} testId={'logout-button'}>
          <Icon svgIcon={Logout} />
          {buttonText}
        </Button>
      )}
      {showModal && (
        <Modal
          title={globalState.headerFooter?.header.fasteTekster.modalErrorTitle ?? ''}
          description={globalState.headerFooter?.header.fasteTekster.modalErrorDescription ?? ''}
          onSuccess={(): void => setModal(false)}
          onClose={(): void => setModal(false)}
          variant={'error' as ModalVariants}
          primaryButtonText={globalState.headerFooter?.header.fasteTekster.modalErrorButtonText ?? ''}
        />
      )}
    </>
  );
};

export default LogoutButton;
