import LanguageLocales from '@helsenorge/core-utils/constants/languages';
import { isDebug } from './hn-debug';
export const REQUEST = 'resources/REQUEST';
export const RECEIVE = 'resources/RECEIVE';
export const FAILURE = 'resources/FAILURE';
/******************* REDUCERS *******************/
function requestResources(project, language) {
    return {
        type: REQUEST,
        project,
        language,
    };
}
export function receiveResources(data, project, language) {
    return {
        type: RECEIVE,
        data,
        project,
        language,
    };
}
export function receiveResourcesFailed(project, language) {
    return {
        type: FAILURE,
        project,
        language,
    };
}
/**
 * Returnerer ResourcesState med oppdatert status avhengig av Action
 * @param resourcesState - incoming ResourcesState
 * @param action - ønsket ResourcesAction (Request, Receive, Failure, Empty)
 */
export const resources = (resourcesState = {}, action) => {
    const project = 'project' in action ? action.project : undefined;
    const language = 'language' in action ? action.language : undefined;
    const key = { project: project, language: language };
    const stringifiedKey = JSON.stringify(key);
    const resources = { ...resourcesState };
    switch (action.type) {
        case REQUEST:
            resources[stringifiedKey] = {
                error: false,
                loading: true,
                loaded: false,
                items: null,
            };
            return resources;
        case RECEIVE:
            resources[stringifiedKey] = {
                error: false,
                loading: false,
                loaded: true,
                items: action.data,
            };
            return resources;
        case FAILURE:
            resources[stringifiedKey] = {
                error: true,
                loading: false,
                loaded: false,
                items: null,
            };
            return resources;
        default:
            return resourcesState;
    }
};
/**
 * Returnerer ResourceItem fra resourcesState basert på project og language
 * @param resourcesState - incoming ResourcesState
 * @param project - project string
 * @param language - language string
 */
export const getResources = (resourcesState, project, language) => {
    const key = JSON.stringify({ project: project, language: language });
    return key in resourcesState ? resourcesState[key].items : {};
};
/**
 * Returnerer ResourceItem fra global Staten basert på project og language
 * @param state - incoming GlobalStateWithResources
 * @param project - project string
 * @param language - language string
 */
export const getResourcesFromState = (state, project, language) => {
    const key = JSON.stringify({ project: project, language: language });
    const value = state.resources[key];
    return value && value.loaded ? value.items : null;
};
/**
 * Returnerer Resource fra global Staten basert på project og language
 * Returnerer ny Resource med default status hvis den ikke finnes
 * @param state - incoming GlobalStateWithResources
 * @param project - project string
 * @param language - language string
 */
export const getResourcesObjectFromState = (state, project, language) => {
    const key = JSON.stringify({ project: project, language: language });
    return key in state.resources ? state.resources[key] : { error: false, loading: false, items: {}, loaded: false };
};
export const getResourceWithId = (json) => {
    return Object.keys(json).reduce((previous, key) => {
        if (typeof json[key] === 'string') {
            previous[key] = json[key] + ' [' + key + ']';
        }
        return previous;
    }, {});
};
export function getModifiedResourcesForTest(resourceText) {
    if (isDebug() && window.document.URL.includes('hjelpetekst=true')) {
        return getResourceWithId(resourceText);
    }
    if (process.env.NODE_ENV === 'test') {
        return Object.keys(resourceText).reduce((previous, current) => {
            previous[current] = current;
            return previous;
        }, {});
    }
    return resourceText;
}
/**
 * Returnerer false om resources allerede ligger i staten
 * @param state - incoming GlobalStateWithResources
 * @param project - project string
 * @param language - language string
 */
export const shouldFetchResources = (state, project, language) => {
    const key = JSON.stringify({ project: project, language: language });
    return !(key in state.resources);
};
/**
 * Returnerer en dispatch av requestResources basert på project name og language
 * @param project - project string
 * @param language - language string
 */
export const fetchResources = (project, language, getResourceFile) => {
    return (dispatch, getState) => {
        if (!language) {
            language = LanguageLocales.NORWEGIAN;
        }
        if (shouldFetchResources(getState(), project, language)) {
            dispatch(requestResources(project, language));
            const success = (data) => {
                const resourceText = getModifiedResourcesForTest(data);
                return dispatch(receiveResources(resourceText, project, language));
            };
            const failure = () => dispatch(receiveResourcesFailed(project, language));
            getResourceFile(project, language).then(success).catch(failure);
        }
        return null;
    };
};
