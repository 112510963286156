import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import Markdown from 'markdown-to-jsx';
import AnchorLink from '@helsenorge/designsystem-react/components/AnchorLink';
import List from '@helsenorge/designsystem-react/components/List';
import Title from '@helsenorge/designsystem-react/components/Title';
import designsystemtypography from '@helsenorge/designsystem-react/scss/typography.module.scss';
import { envSafeUrl } from './safe-html';
import { sanitize } from '../../trusted-types';
import styles from './styles.module.scss';
const SafeHTML = ({ html, tagName, testId, className, ...rest }) => {
    if (!html) {
        return null;
    }
    if (typeof html === 'string') {
        const CustomTag = tagName || 'span';
        return (_jsx(CustomTag, { className: classNames(designsystemtypography['anchorlink-wrapper'], styles['safe-html'], className), "data-testid": testId, dangerouslySetInnerHTML: { __html: sanitize(envSafeUrl(html)) }, ...rest }));
    }
    else {
        if (!html?.value) {
            return null;
        }
        const CustomTag = tagName || 'div';
        return (_jsx(CustomTag, { className: classNames(styles['safe-html'], className), "data-testid": testId, children: _jsx(Markdown, { options: {
                    wrapper: React.Fragment,
                    overrides: {
                        h2: {
                            component: Title,
                            props: {
                                htmlMarkup: 'h2',
                                appearance: 'title2',
                            },
                        },
                        h3: {
                            component: Title,
                            props: {
                                htmlMarkup: 'h3',
                                appearance: 'title3',
                            },
                        },
                        h4: {
                            component: Title,
                            props: {
                                htmlMarkup: 'h4',
                                appearance: 'title4',
                            },
                        },
                        h5: {
                            component: Title,
                            props: {
                                htmlMarkup: 'h5',
                                appearance: 'title5',
                            },
                        },
                        a: {
                            component: AnchorLink,
                        },
                        ul: {
                            component: List,
                            props: {
                                variant: 'bullet',
                            },
                        },
                        ol: {
                            component: List,
                            props: {
                                variant: 'numbered',
                            },
                        },
                        li: {
                            component: List.Item,
                        },
                    },
                }, children: envSafeUrl(html.value) }) }));
    }
};
export default SafeHTML;
