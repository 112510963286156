import { ZIndex } from '@helsenorge/designsystem-react';
import { getCookieValue, getSuffix } from '@helsenorge/framework-utils/cookie';
import { error } from '@helsenorge/framework-utils/logger';
import { setSharedCookie } from '@helsenorge/framework-utils/shared-cookies';
/**
 * Returnerer navnet på cookien som lagrer data om pågående undersøkelse
 * @returns cookie-navn
 */
export const getActiveSurveyCookieName = () => getSuffix('hn-active-survey');
const BASE64_PREFIX = 'base64:';
export const SURVEY_Z_INDEX = ZIndex.ExpanderTrigger + 100;
/**
 * Returnerer navn på cookie som lagrer data om bruker har takket ja eller nei til å delta i en undersøkelse
 * @param surveyId Unik id på undersøkelse
 * @returns cookie-navn
 */
export const getSurveyInvitationCookieName = (surveyId) => {
    return getSuffix(`hn-survey-${surveyId}`);
};
/**
 * Lagrer cookie som inneholder JSON på tvers av åpne sider og tjenester
 * @param name navn på cookie
 * @param value verdi på cookie
 * @param maxAgeInDays levetid på cookie i dager

 */
export const setSharedJsonCookie = (name, value, maxAgeInDays = 365) => setSharedCookie(name, BASE64_PREFIX + window.btoa(JSON.stringify(value)), maxAgeInDays);
/**
 * Henter verdien til en cookie som inneholder JSON, enten den er lagret som base64 eller ikke
 * @param cookieName navn på cookie
 * @returns cookie som JSON, eller false dersom cookie ikke er gyldig JSON
 */
export const getJsonCookieValue = (name) => {
    const cookieValue = getCookieValue(name);
    if (cookieValue && typeof cookieValue === 'string') {
        try {
            return cookieValue.startsWith(BASE64_PREFIX)
                ? JSON.parse(window.atob(cookieValue.slice(BASE64_PREFIX.length)))
                : JSON.parse(cookieValue);
        }
        catch (e) {
            // Eventuelle feil må håndteres der funksjonen kalles, f.eks. ved å slette cookien dersom den ikke kan leses
            error('Feil under lesing av json-cookie', e);
        }
    }
    return false;
};
