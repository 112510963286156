import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { trackFeedback } from '@helsenorge/framework-utils/adobe-analytics';
import { getCookieValue } from '@helsenorge/framework-utils/cookie';
import { setSharedCookie, deleteSharedCookie } from '@helsenorge/framework-utils/shared-cookies';
import InvitationStep from './invitation-step';
import StartSurvey from './start-survey';
import { getActiveSurveyCookieName, getJsonCookieValue, getSurveyInvitationCookieName, setSharedJsonCookie } from './utils';
import SurveyInvitationWrapper from './wrapper';
var SurveyInvitationState;
(function (SurveyInvitationState) {
    SurveyInvitationState[SurveyInvitationState["Loading"] = 0] = "Loading";
    SurveyInvitationState[SurveyInvitationState["Hidden"] = 1] = "Hidden";
    SurveyInvitationState[SurveyInvitationState["Show"] = 2] = "Show";
    SurveyInvitationState[SurveyInvitationState["Accepted"] = 3] = "Accepted";
    SurveyInvitationState[SurveyInvitationState["Pending"] = 4] = "Pending";
})(SurveyInvitationState || (SurveyInvitationState = {}));
/**
 * Navn på event som brukes til å vise en invitasjon til undersøkelse som i utgangspunktet er skjult.
 */
const SHOW_HIDDEN_SURVEY_INVITATION_EVENT_NAME = 'hn-survey-invitation-event-showhiddensurveyinvitation';
/**
 * Vis en undersøkelse som i utgangspunktet er skjult
 */
export const showHiddenSurveyInvitation = () => {
    document.dispatchEvent(new CustomEvent(SHOW_HIDDEN_SURVEY_INVITATION_EVENT_NAME));
};
const isActiveSurveyCookie = (cookie) => {
    return (!!cookie?.id &&
        !!cookie?.text &&
        !!cookie?.surveyUrl &&
        !!cookie?.landmarkLabel);
};
const SurveyInvitation = ({ survey }) => {
    const [state, setState] = useState(SurveyInvitationState.Loading);
    const [showHiddenInvitation, setShowHiddenInvitation] = useState(false);
    const [activeSurveyDetails, setActiveSurveyDetails] = useState();
    const { id = '', surveyUrl = '', redirectImmediately = false, invitationTitle, invitationText1, invitationText2, yes, no, close, instructionsTitle, instructionsText, instructionsOk, done = '', landmarkLabel = '', } = survey || {};
    const activeSurveyCookieName = getActiveSurveyCookieName();
    const cookieName = getSurveyInvitationCookieName(id);
    const acceptSurvey = () => {
        trackFeedback('spørreundersøkelse popup', 'spørreundersøkelse popup: ja');
        setSharedCookie(cookieName, 'yes');
        setSharedJsonCookie(activeSurveyCookieName, { id, text: done, surveyUrl, landmarkLabel }, 1);
        setState(SurveyInvitationState.Accepted);
    };
    const acceptInstructions = () => {
        setSharedCookie(cookieName, 'yes');
        setSharedJsonCookie(activeSurveyCookieName, { id, text: done, surveyUrl, landmarkLabel }, 1);
        setState(SurveyInvitationState.Pending);
    };
    const declineSurvey = () => {
        trackFeedback('spørreundersøkelse popup', 'spørreundersøkelse popup: nei');
        setSharedCookie(cookieName, 'no');
        setState(SurveyInvitationState.Hidden);
    };
    const closeSurvey = () => {
        trackFeedback('spørreundersøkelse popup', 'spørreundersøkelse popup: lukk');
        setSharedCookie(cookieName, 'no');
        setState(SurveyInvitationState.Hidden);
    };
    const startSurvey = () => {
        trackFeedback('spørreundersøkelse popup', 'spørreundersøkelse popup: start');
        deleteSharedCookie(activeSurveyCookieName);
        setSharedCookie(getSurveyInvitationCookieName(activeSurveyDetails?.id ?? id), 'no');
        setState(SurveyInvitationState.Hidden);
        window.open(activeSurveyDetails?.surveyUrl ?? surveyUrl);
    };
    useEffect(() => {
        const activeSurveyCookie = getJsonCookieValue(activeSurveyCookieName);
        if (isActiveSurveyCookie(activeSurveyCookie)) {
            setActiveSurveyDetails(activeSurveyCookie);
            setState(SurveyInvitationState.Pending);
            return;
        }
        else {
            deleteSharedCookie(activeSurveyCookieName);
        }
        const surveyCookie = getCookieValue(cookieName);
        switch (surveyCookie) {
            case 'no':
                setState(SurveyInvitationState.Hidden);
                break;
            case 'yes':
                setState(SurveyInvitationState.Accepted);
                break;
            default:
                setState(SurveyInvitationState.Show);
                break;
        }
        const handleShowHiddenSurveyInvitation = () => {
            setShowHiddenInvitation(true);
        };
        document.addEventListener(SHOW_HIDDEN_SURVEY_INVITATION_EVENT_NAME, handleShowHiddenSurveyInvitation);
        return () => {
            document.removeEventListener(SHOW_HIDDEN_SURVEY_INVITATION_EVENT_NAME, handleShowHiddenSurveyInvitation);
        };
    }, [survey]);
    if (!activeSurveyDetails && !survey) {
        return null;
    }
    if (state === SurveyInvitationState.Loading || state === SurveyInvitationState.Hidden) {
        return null;
    }
    if (state === SurveyInvitationState.Pending) {
        return (_jsx(SurveyInvitationWrapper, { ariaLabel: activeSurveyDetails?.landmarkLabel ?? landmarkLabel, children: _jsx(StartSurvey, { onClick: startSurvey, children: activeSurveyDetails?.text ?? done }) }));
    }
    if (state === SurveyInvitationState.Accepted) {
        return (_jsx(SurveyInvitationWrapper, { ariaLabel: landmarkLabel, children: _jsx(InvitationStep, { title: instructionsTitle, primaryButtonText: instructionsOk, onPrimary: acceptInstructions, closeButtonText: close, onClose: acceptInstructions, children: _jsx("p", { children: instructionsText }) }) }));
    }
    if (survey?.hidden && !showHiddenInvitation) {
        return null;
    }
    return (_jsx(SurveyInvitationWrapper, { ariaLabel: landmarkLabel, children: _jsxs(InvitationStep, { title: invitationTitle, primaryButtonText: yes, onPrimary: redirectImmediately ? startSurvey : acceptSurvey, secondaryButtonText: no, onSecondary: declineSurvey, closeButtonText: close, onClose: closeSurvey, children: [_jsx("p", { children: invitationText1 }), invitationText2 && _jsx("p", { children: invitationText2 })] }) }));
};
export default SurveyInvitation;
