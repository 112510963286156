import { jsx as _jsx } from "react/jsx-runtime";
import { getMaxSuggestions } from './search-utils';
import cmsstyles from './styles.module.scss';
const SearchAutoSuggest = ({ query, cachedAutosuggestions, suggestionsRef, highlightedSuggestion, handleKeyDown, handleSuggestionClick, isVisible, }) => {
    const currentSuggestions = cachedAutosuggestions.find(autosuggestion => autosuggestion.query === query);
    if (!(currentSuggestions && currentSuggestions.suggestions && currentSuggestions.suggestions.length > 0) ||
        !isVisible ||
        !query?.trim()) {
        return null;
    }
    return (_jsx("ul", { className: cmsstyles['search__suggestions-list'], children: currentSuggestions.suggestions?.map((suggestion, index) => {
            const count = index + 1;
            const maxSuggestions = getMaxSuggestions();
            if (count > maxSuggestions)
                return;
            return (_jsx("li", { className: `${cmsstyles['search__suggestions-list__item']} ${count > 1 ? cmsstyles['search__suggestions-list__item--bordertop'] : ''}`, children: _jsx("button", { ref: suggestionsRef.current[index], type: "button", role: "option", "aria-selected": highlightedSuggestion === index, className: cmsstyles['search__suggestions-list__item__button'], onKeyDown: handleKeyDown, onClick: () => handleSuggestionClick(suggestion), children: _jsx("span", { children: suggestion }) }) }, suggestion));
        }) }));
};
export default SearchAutoSuggest;
