import { useEffect, useState, useContext } from 'react';

import { NotificationMessage } from '../../types/entities';

import LanguageLocales from '@helsenorge/core-utils/constants/languages';
import { getCookieValue } from '@helsenorge/framework-utils/cookie';
import { useSetUserLanguageEvent } from '@helsenorge/framework-utils/hn-language';
import { getPath } from '@helsenorge/framework-utils/hn-page';
import { erTjenester } from '@helsenorge/framework-utils/hn-proxy-service';
import { warn } from '@helsenorge/framework-utils/logger';
import { deleteSharedCookie, setSharedCookie } from '@helsenorge/framework-utils/shared-cookies';
import { HeaderFooterEvents } from '@helsenorge/framework-utils/web-component/constants';
import { SubscribeContext } from '@helsenorge/framework-utils/web-component/context';
import { HNeventSetSurveys } from '@helsenorge/framework-utils/web-component/events';

import { getContentForContentReference, getContentForPath, IAdditionalPageContent } from './get-additionalPageContent';

interface ClosedMessage {
  contentId: string;
  timestamp: number;
}

/**
 * Navn på cookie der lukkede driftsmeldinger lagres
 */
export const DRIFTSMELDING_COOKIE = 'hn-driftsmelding-closed';

/**
 * Levetid på cookie i dager
 */
export const DRIFTSMELDING_COOKIE_EXPIRES_DAYS = 183;

/**
 * Formaterer json til et lightweight cookie format
 * Fra [{"contentId":"123","timestamp":1638464584}, {"contentId":"456","timestamp":1638464584}]
 * Til 123:1638464584|456:1638464584
 * @param closedMessages Liste med lukkede meldinger
 * @returns Lukkede meldinger formattert for cookie
 */
export const formatJsonCookie = (closedMessages: ClosedMessage[]): string => {
  if (closedMessages.length === 0) {
    return '';
  }
  const json = JSON.stringify(closedMessages);
  let formattedJson = json.replace(/},{/g, '|');
  formattedJson = formattedJson.replace(/contentId|timestamp|"|\[|{|}|]|:/g, '');

  return formattedJson.replace(/,/g, ':');
};

/**
 * Reverserer formatering av lightweight cookie
 * @param formattedJson Innhold i cookie
 * @returns Liste med lukkede meldinger
 */
export const reverseFormatJsonCookie = (formattedJson: string): ClosedMessage[] => {
  if (formattedJson.length === 0) return [];
  const distinctList: Array<ClosedMessage> = [];

  formattedJson.split('|').forEach(item => {
    const splitItem = item.split(':');
    return distinctList.push({
      contentId: splitItem[0],
      timestamp: Number(splitItem[1]),
    });
  });

  return distinctList;
};

/**
 * Hent lukkede meldinger fra cookie
 * @returns Liste med lukkede meldinger
 */
const getClosedMessages = (): ClosedMessage[] => {
  try {
    const closedDriftsmeldingerJson = getCookieValue(DRIFTSMELDING_COOKIE);

    if (typeof closedDriftsmeldingerJson === 'string') {
      return reverseFormatJsonCookie(closedDriftsmeldingerJson);
    }
    return [];
  } catch (error) {
    deleteSharedCookie(DRIFTSMELDING_COOKIE);

    warn('Cleared closed driftsmelding cookie', error);
    return [];
  }
};

/**
 * Skjuler infomeldinger på åpne sider, der de vises serversiderendret i stedet
 * @param message Drifts- eller infomelding
 * @returns true dersom melding skal vises
 */
const filterInfomeldinger = (message: NotificationMessage): boolean => erTjenester() || message.messageType !== 'infomelding';

/**
 * Skjuler lukkede meldinger
 * @param message Melding som skal sjekkes
 * @param closedMessages Liste med meldinger som er lukket
 * @returns true dersom meldingen skal vises
 */
const filterClosedMessages = (message: NotificationMessage, closedMessages: ClosedMessage[]): boolean =>
  !closedMessages.find(closed => closed.contentId === message.id && closed.timestamp === message.unixTimeStamp);

/**
 * Dedupliserer liste med lukkede meldinger slik at de ikke lagres dobbelt
 * @param unique Liste med unike lukkede meldinger
 * @param melding Melding som skal sjekkes
 * @returns Liste med unike lukkede meldinger
 */
const toUniqueClosedMessages = (unique: ClosedMessage[], closedMessage: ClosedMessage): ClosedMessage[] =>
  !unique.some(x => x.contentId === closedMessage.contentId) ? [...unique, closedMessage] : unique;

/**
 * Sorter meldinger etter timestamp
 * @param a Melding
 * @param b Melding
 * @returns > 0 dersom a skal sorteres etter b, < 0 dersom a skal sorteres før b, eventuelt 0 om de er like
 */
const sortByTimestamp = (a: ClosedMessage, b: ClosedMessage): number => b.timestamp - a.timestamp;

/**
 * Hent driftsmeldinger, infomeldinger og spørreundersøkelser
 * @param contentReference Side-ID i CMS (trengs kun på åpne sider)
 * @param languageCode Språkkode
 * @returns Liste med meldinger og callback for å lagre at meldinger lukkes
 */
export const useAdditionalPageContent = (
  contentReference?: string,
  language?: LanguageLocales
): [NotificationMessage[], (message: NotificationMessage) => void] => {
  const subscribe = useContext(SubscribeContext);
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const [path, setPath] = useState<string>();
  const [closedMessages, setClosedMessages] = useState<ClosedMessage[]>([]);
  const [content, setContent] = useState<IAdditionalPageContent>();

  useEffect(() => {
    subscribe(HeaderFooterEvents.setdriftsmeldingpath, (event: CustomEvent) => {
      setPath(event.detail.path);
    });
    const storedClosedMessages = getClosedMessages();
    setClosedMessages(storedClosedMessages);
  }, []);

  useEffect(() => {
    if (contentReference) {
      getContentForContentReference(contentReference, currentLanguage)
        .then(data => data && setContent(data))
        .catch(); // Feil håndteres i cms-content-api-service
    } else {
      getContentForPath(path || getPath() || '', currentLanguage)
        .then(data => data && setContent(data))
        .catch(); // Feil håndteres i cms-content-api-service
    }
  }, [contentReference, path, currentLanguage]);

  useEffect(() => {
    content?.surveys?.length && HNeventSetSurveys(content.surveys);
  }, [content?.surveys]);

  const handleUserLanguageEvent = (newLanguage: LanguageLocales): void => {
    if (erTjenester()) {
      setCurrentLanguage(newLanguage);
    }
  };

  useSetUserLanguageEvent(handleUserLanguageEvent);

  const handleClosed = ({ id: contentId, unixTimeStamp: timestamp }: NotificationMessage): void => {
    const prevClosed = getClosedMessages() ?? [];
    prevClosed.push({ contentId, timestamp });
    prevClosed.sort(sortByTimestamp);
    const unique = prevClosed.reduce(toUniqueClosedMessages, [] as ClosedMessage[]);

    setSharedCookie(DRIFTSMELDING_COOKIE, formatJsonCookie(unique), DRIFTSMELDING_COOKIE_EXPIRES_DAYS);
    setClosedMessages(unique);
  };

  const messages = content?.messages.filter(filterInfomeldinger).filter(message => filterClosedMessages(message, closedMessages)) ?? [];

  return [messages, handleClosed];
};
